import React, { useEffect, useState } from "react";
import { homeDashBoardCardTypes } from "./Constants";
import {
  getCurrentYearPlanning,
  getPlanningCompleted,
  getProcedureCompleted,
  getProcedureInProgress,
} from "../../../../Services/DashBoard.Services";
import { NWSGrid, NWSPopup } from "@nws/nws-ui-library";
import { clientDashBoardColumns } from "./DashboardCard.data";

const DashboardCardPopup = ({
  popUpOpen,
  setPopUpOpen,
  itemDetails,
  setItemDetails,
  currentFiscalYearId,
  t,
}) => {
  const { type, label } = itemDetails;
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [currentFiscalYearId, type]);

  const fetchData = async () => {
    let result;
    switch (type) {
      case homeDashBoardCardTypes.PlanningCompleted:
        result = await getPlanningCompleted(currentFiscalYearId);
        break;
      case homeDashBoardCardTypes.CurrentYearPlanning:
        result = await getCurrentYearPlanning(currentFiscalYearId);
        break;
      case homeDashBoardCardTypes.ProcedureCompleted:
        result = await getProcedureCompleted(currentFiscalYearId);
        break;
      case homeDashBoardCardTypes.ProcedureInProgress:
        result = await getProcedureInProgress(currentFiscalYearId);
        break;
      default:
        return;
    }

    setData(result.result.data);
  };

  const onClose = () => {
    setData([]);
    setItemDetails({
      type: "",
      label: "",
    });
    setPopUpOpen(false);
  };

  return (
    <NWSPopup
      onClose={onClose}
      className=""
      open={popUpOpen}
      title={label}
      width="md"
      body={
        <div className="">
          <NWSGrid
            newTable={true}
            columns={clientDashBoardColumns(t)}
            dataSource={data || []}
            hasPageSizeDropdown={false}
          />
        </div>
      }
    />
  );
};

export default DashboardCardPopup;
