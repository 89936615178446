export const homeDashBoardCardTypes = {
  Engagements: "Engagements",
  PlanningCompleted: "Planning Completed",
  CurrentYearPlanning: "Current Year Planning",
  ProcedureInProgress: "Procedure In Progress",
  ProcedureCompleted: "Procedure Completed",
};

export const homeDashboardCardClientTypes = {
  ActiveClients: "Active Clients",
  NewEngegements: "Active Engagements",
  EngagementContinuous: "Active EngagementContinues",
};

export const homeDashboardCardMenuId = {
  Engagements: 38,
  PlanningCompleted: 39,
  CurrentYearPlanning: 41,
  ProcedureInProgress: 40,
  ProcedureCompleted: 37,
};

export const idleStatus = "idle";
