import { IconNames } from "@nws/nws-ui-library";
import { checkActionPermision } from "../../../../Helpers/helper";
import {
  homeDashBoardCardTypes,
  homeDashboardCardClientTypes,
  homeDashboardCardMenuId,
} from "./Constants";

export const clientDashBoardColumns = (t) => [
  {
    title: t("commonLabels.clientName"),
    dataIndex: "clientName",
    width: "40%",
  },
  {
    title: t("commonLabels.comments"),
    dataIndex: "comments",
    width: "40%",
  },
  {
    title: t("commonLabels.fisicalYear"),
    dataIndex: "fiscalYear",
    width: "10%",
  },
];

export const dashBoardCardData = (t, menuListArr, accessView) => [
  {
    id: "1",
    iconName: IconNames.IconEngagements,
    displayLabel: t("commonLabels.engagementsLabel"),
    type: homeDashBoardCardTypes.Engagements,
    menuId: homeDashboardCardMenuId.Engagements,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.Engagements,
      accessView,
      true
    ),
    children: [
      {
        id: "1",
        displayLabel: t("dashBoardCards.engagementsCard.activeClientLabel"),
        type: homeDashboardCardClientTypes.ActiveClients,
        hasBorder: true,
      },
      {
        id: "2",
        displayLabel: t("dashBoardCards.engagementsCard.newEngagementLabel"),
        type: homeDashboardCardClientTypes.NewEngegements,
        hasBorder: true,
      },
      {
        id: "3",
        displayLabel: t(
          "dashBoardCards.engagementsCard.engagementContinuousLabel"
        ),
        type: homeDashboardCardClientTypes.EngagementContinuous,
        hasBorder: false,
      },
    ],
  },
  {
    id: "2",
    iconName: IconNames.IconPlanningCompleted,
    displayLabel: t("dashBoardCards.clientDetailsCard.planningCompletedLabel"),
    type: homeDashBoardCardTypes.PlanningCompleted,
    menuId: homeDashboardCardMenuId.PlanningCompleted,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.PlanningCompleted,
      accessView,
      true
    ),
  },
  {
    id: "3",
    iconName: IconNames.CurrentYearPlanning,
    displayLabel: t(
      "dashBoardCards.clientDetailsCard.currentYearPlanningLabel"
    ),
    type: homeDashBoardCardTypes.CurrentYearPlanning,
    menuId: homeDashboardCardMenuId.CurrentYearPlanning,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.CurrentYearPlanning,
      accessView,
      true
    ),
  },
  {
    id: "4",
    iconName: IconNames.IconProcedureInProgress,
    displayLabel: t(
      "dashBoardCards.clientDetailsCard.procedureInProgressLabel"
    ),
    type: homeDashBoardCardTypes.ProcedureInProgress,
    menuId: homeDashboardCardMenuId.ProcedureInProgress,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.ProcedureInProgress,
      accessView,
      true
    ),
  },
  {
    id: "5",
    iconName: IconNames.IconProcedureCompleted,
    displayLabel: t("dashBoardCards.clientDetailsCard.procedureCompletedLabel"),
    type: homeDashBoardCardTypes.ProcedureCompleted,
    menuId: homeDashboardCardMenuId.ProcedureCompleted,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.ProcedureCompleted,
      accessView,
      true
    ),
  },
];
