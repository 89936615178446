import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { dashBoardTypes } from "../../components/FinancialAuditing/Clients/shared/ClientTypes";

const useHomeDashBoardDetails = (
  currentFiscalYearId = 0,
  getDataMethod,
  querykeyword,
  type
) => {
  const shouldFetchData =
    currentFiscalYearId !== 0 && currentFiscalYearId !== undefined;
  const { status, isLoading, error, data, refetch } = useQuery(
    querykeyword,
    () => getDataMethod(currentFiscalYearId).then(({ result }) => result.data),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    if (shouldFetchData) {
      refetch();
    }
  }, [currentFiscalYearId, refetch]);

  const result = useMemo(() => {
    switch (type) {
      case dashBoardTypes.ProcedureInProgress:
        return {
          ProcedureInProgressLoading: isLoading,
          ProcedureInProgressError: error,
          ProcedureInProgressCount: data,
          ProcedureInProgressStatus: status,
        };
      case dashBoardTypes.ProcedureCompleted:
        return {
          procedureCompletedLoading: isLoading,
          procedureCompletedError: error,
          procedureCompletedCount: data,
          procedureStatus: status,
        };
      case dashBoardTypes.CurrentYearPlanning:
        return {
          currentYearCountLoading: isLoading,
          currentYearCountError: error,
          currentYearCount: data,
          currentYearStatus: status,
        };
      case dashBoardTypes.PlanningCompleted:
        return {
          PlanningCompletedLoading: isLoading,
          PlanningCompletedError: error,
          planningCount: data,
          planningStatus: status,
        };
      default:
        return { isLoading, error, count: data, status };
    }
  }, [isLoading, error, data, type, status]);

  return result;
};

export default useHomeDashBoardDetails;
