import React, { useContext, useState } from "react";
import { homeDashBoardCardTypes } from "./Shared/Constants";
import useHomeDashBoardDetails from "../../../hooks/HomeDashBoard/useHomeDashBoardDetails";
import {
  getCurrentYearPlanningCount,
  getPlanningCompletedCount,
  getProcedureCompletedCount,
  getProcedureInProgressCount,
} from "../../../Services/DashBoard.Services";
import { dashBoardCardData } from "./Shared/DashboardCard.data";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";
import { GlobalStateContext } from "../../../Context/GlobalStateContext";
import { accessLevels } from "../../../Helpers/helper";
import { useMenuList } from "../../../hooks/common/useMenuList";
import useActiveClients from "../../../hooks/HomeDashBoard/useActiveClients";
import { DashboardCardWithChild } from "./Shared/DashboardCardWithChild";
import { DashboardCardWithoutChild } from "./Shared/DashboardCardWithoutChild";
import DashboardCardPopup from "./Shared/DashboardCardPopup";

export const DashboardCard = () => {
  const { t } = useTranslate();
  const { menuListArr } = useMenuList();
  const { globalState } = useContext(GlobalStateContext);
  const clientAndFiscalYearsData = globalState?.clientFiscalYears || {};
  const { currentFiscalYear = { id: 0, value: "" } } = clientAndFiscalYearsData;
  const cardDetails = dashBoardCardData(
    t,
    menuListArr,
    accessLevels.AccessView
  );

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState({
    type: "",
    label: "",
  });

  const dashboardData = {
    planningCompleted: useHomeDashBoardDetails(
      currentFiscalYear?.fiscalYearID,
      getPlanningCompletedCount,
      "planningCompletedCount",
      homeDashBoardCardTypes.PlanningCompleted
    ),
    currentYear: useHomeDashBoardDetails(
      currentFiscalYear?.fiscalYearID,
      getCurrentYearPlanningCount,
      "currentYearPlanningCount",
      homeDashBoardCardTypes.CurrentYearPlanning
    ),
    procedureCompleted: useHomeDashBoardDetails(
      currentFiscalYear?.fiscalYearID,
      getProcedureCompletedCount,
      "procedureCompletedCount",
      homeDashBoardCardTypes.ProcedureCompleted
    ),
    procedureInProgress: useHomeDashBoardDetails(
      currentFiscalYear?.fiscalYearID,
      getProcedureInProgressCount,
      "procedureInProgressCount",
      homeDashBoardCardTypes.ProcedureInProgress
    ),
  };

  const { clientListLoading, clientActiveList, clientListStatus } =
    useActiveClients(currentFiscalYear?.fiscalYearID || 0);

  const handlePopupClick = (item) => {
    setItemDetails({
      type: item.type,
      label: item.displayLabel,
    });
    setPopUpOpen(true);
  };

  return (
    <div className="grid-container">
      {cardDetails.map((item, index) =>
        item.children ? (
          <DashboardCardWithChild
            key={`home-header-card-${index}`}
            item={item}
            handleOnClick={() => {}}
            clientActiveList={clientActiveList}
            clientListLoading={clientListLoading}
            hasChild={true}
            clientListStatus={clientListStatus}
          />
        ) : (
          <DashboardCardWithoutChild
            key={`home-header-card-${index}`}
            item={item}
            handleOnClick={() => handlePopupClick(item)}
            dashboardData={dashboardData}
            menuListArr={menuListArr}
            popUpOpen={popUpOpen}
            setPopUpOpen={setPopUpOpen}
            currentFiscalYearId={currentFiscalYear?.fiscalYearID}
            t={t}
            hasChild={false}
          />
        )
      )}
      {popUpOpen && (
        <DashboardCardPopup
          popUpOpen={popUpOpen}
          setPopUpOpen={setPopUpOpen}
          itemDetails={itemDetails}
          setItemDetails={setItemDetails}
          currentFiscalYearId={currentFiscalYear?.fiscalYearID}
          t={t}
        />
      )}
    </div>
  );
};
